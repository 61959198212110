// src/AnalyticsContext.js

import React, { createContext, useContext } from 'react';
import { analytics } from '../../firebase';


const AnalyticsContext = createContext(analytics);

export const useAnalytics = () => useContext(AnalyticsContext);

export const AnalyticsProvider = ({ children }) => {
  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};
