import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AnalyticsProvider } from "./front-office/component/Analysistics";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
  <AnalyticsProvider>
  <App />
  </AnalyticsProvider>
      
  </React.StrictMode>
);
